import React, { useState } from 'react'

import './accordion.css'
const faqs = [
    {
        question: 'What is indexmypage.com?',
        answer:
      "Indexmypage.com offers a tool designed to help website owners and SEO professionals Instantly Index their URLs. In our tool you can input up to 100 URLs at one time making the indexing process extremely efficient. By using our tool, you can expedite the indexing process and improve your website's visibility in search engine results."
    },
    {
        question: 'How do I use indexmypage.com',
        answer:
      "Watch our step-by-step tutorial on how to use indexmypage.com effectively by clicking <a href='https://youtu.be/bcpRWVwbMCY' target='_blank' rel='noopener noreferrer'>here</a>"
    },
    {
        question:
      'Is this different than requesting indexing on Google Search Console?',
        answer: 'Yes it is, our services instantly index your URLs!'
    },
    {
        question: 'How does indexmypage.com work?',
        answer:
      'Our tool uses a simple submission process. Just enter the URL of the page you want to index, and our system will submit it to google for indexing. Indexing is instant and you can check your results in Google Search Console right away.'
    },
    {
        question: 'Which search engines does indexmypage.com support?',
        answer:
      'Currently, our tool supports submissions to major search engines like Google, Bing, and Yahoo. We are continuously working on adding more search engines to our list.'
    },
    {
        question: 'Is there a limit to the number of pages I can submit?',
        answer:
      "Yes, there is a daily submission limit based on your subscription plan. We also have a limit of 100 URL's at a time on our bulk editor. Please check our pricing page for more details on submission limits and plan features."
    },
    {
        question: 'How long does it take for a page to get indexed?',
        answer: 'The indexing time is instantaneous.'
    },
    {
        question: 'Do I need to submit my pages multiple times?',
        answer:
      'No, you only need to submit your pages once. However, if you make significant changes to your page content, you may consider resubmitting it for indexing.'
    },
    {
        question: 'Is indexmypage.com safe to use?',
        answer:
      'Absolutely! Our tool is designed with security in mind. We do not store any sensitive information, and all submissions are done securely.'
    },
    {
        question: 'Do you offer customer support?',
        answer:
      'Yes, we offer customer support via email (Support@indexmypage.com). We also have a how to video on Youtube, here. If you have any questions or need assistance, our team is here to help.'
    },
    {
        question: 'How can I get started with indexmypage.com?',
        answer:
      'Getting started is easy! Just sign up for an account, add your website, and start submitting your pages for indexing.'
    },
    {
        question: 'Can you do bulk URL Removals?',
        answer:
      "Yes! On the same tool you can check the option “Removal” and your credits will be used to remove URL's from googles index."
    }
]
const Accordion = () => {
    const [activeIndex, setActiveIndex] = useState(null)

    const handleToggle = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null)
        } else {
            setActiveIndex(index)
        }
    }

    return (
        <div className="accordion">
            <h1 className="accordion__heading">Frequently Asked Questions</h1>
            <div className="accordion__content">
                {faqs.map((faq, index) => (
                    <div key={index} className="accordion__item">
                        <div
                            className={`accordion__question ${activeIndex === index ? 'active' : ''
                            }`}
                            onClick={() => handleToggle(index)}
                        >
                            {faq.question}
                        </div>
                        <div
                            className={`accordion__answer ${activeIndex === index ? 'show' : ''
                            }`}
                            dangerouslySetInnerHTML={{ __html: faq.answer }}
                        ></div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Accordion
