import React, { createContext, useContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'

const apiUrl = process.env.REACT_APP_API_URL

const AuthContext = createContext(null)

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
    const [statusReady, setStatusReady] = useState(false)
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [user, setUser] = useState(null)
    const [accounts, setAccounts] = useState([])
    const [error, setError] = useState(null)

    const location = useLocation()

    const logOut = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
                method: 'POST',
                credentials: 'include' // important for cookies
            })
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`)
            }
            setIsLoggedIn(false)
            setUser(null)
        } catch (e) {
            console.error('Error logging out:', e)
            setError('Error logging out')
        }
    }

    const fetchAccounts = async () => {
        try {
            const response = await axios.get(`${apiUrl}/my_google_creds`, {
                withCredentials: true
            })
            setAccounts(response.data)
        } catch (e) {
            setError(e?.response?.data?.message || 'Error fetching accounts')
        }
    }

    const checkAuthStatus = async () => {
        try {
            const response = await axios.get(`${apiUrl}/user`, {
                withCredentials: true
            })
            const { data } = response

            setIsLoggedIn(data.logged_in)
            setUser(data.user)
        } catch (e) {
            setIsLoggedIn(false)
            setUser(null)
        }
        setStatusReady(true)
    }

    useEffect(() => {
        if (statusReady && isLoggedIn) {
            fetchAccounts()
        }
    }, [isLoggedIn, statusReady])

    useEffect(() => {
        checkAuthStatus()
    }, [location])

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn,
                user,
                setIsLoggedIn,
                setUser,
                logOut,
                statusReady,
                accounts,
                setAccounts,
                fetchAccounts,
                error
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
