import React from 'react'
import { useNavigate } from 'react-router-dom'
import './TokensForm.css'

const TokensForm = () => {
    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/pricing')
    }

    return (
        <div className="tokens-container">
            <button onClick={handleClick} className="buy-tokens-button">
        Get Tokens
            </button>
        </div>
    )
}

export default TokensForm
