import React, { useEffect, useState } from 'react'
import { useAuth } from '../../AuthContext'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

import './profile.css'

const apiUrl = process.env.REACT_APP_API_URL

const Profile = () => {
    const [error, setError] = useState(null)
    const { user, setUser, isLoggedIn, statusReady, accounts, setAccounts } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (statusReady && !isLoggedIn) navigate('/login')
    }, [isLoggedIn, statusReady, navigate])

    const deleteAccount = async (id) => {
        try {
            await axios.delete(
                `${apiUrl}/delete_google_creds`,
                {
                    data: { account_id: id },
                    withCredentials: true
                }
            )
            setAccounts(accounts.filter((account) => account.id !== id))
        } catch (error) {
            console.error(error)
            setError(error.response?.data?.message || 'Error deleting account')
        }
    }

    const cancelSub = async () => {
        try {
            const response = await axios.put(
                `${apiUrl}/cancel_sub`,
                {},
                {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
            setUser(response.data)
        } catch (error) {
            console.error(error)
            setError(error.response?.data?.message || 'Error canceling subscription')
        }
    }

    return (
        <div className="profile-container">
            <div className="profile-content">
                <h1>Profile</h1>
                <p>
                    <strong>Username:</strong> {user?.username}
                </p>
                <p>
                    <strong>Email:</strong> {user?.email}
                </p>
                <p>
                    <strong>Tokens:</strong> {user?.tokens}
                </p>
                <p>
                    <strong>Subscription Plan:</strong> {user?.subscription_plan}
                </p>
                <p>
                    <strong>Subscription Status:</strong> {user?.subscription_status}
                </p>
                {accounts.length > 0 && (
                    <div>
                        <h3>Google Accounts</h3>
                        <ul className='account-list'>
                            {accounts.map((account) => (
                                <li key={account.id}>
                                    <strong>{account.google_email}</strong>
                                    <button className='remove-account-btn' onClick={() => deleteAccount(account.id)}>
                                        Remove
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                )
                }
                {user?.subscription_status === 'active' && (
                    <button className="cancel-sub-btn" onClick={cancelSub}>
                        Cancel Subscription
                    </button>
                )}
                {error && <div className="error">{error}</div>}
            </div>

        </div>
    )
}

export default Profile
