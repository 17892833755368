import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../AuthContext'
import logo from '../../assets/images/index-my-page-logo.webp'
import './navbar.css'

const NavBar = () => {
    const { isLoggedIn, logOut } = useAuth()
    const [isNavCollapsed, setIsNavCollapsed] = useState(true)

    const navigate = useNavigate()

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed)

    const handleLogout = () => {
        logOut()
        handleNavCollapse()
        navigate('/')
    }

    const loggedOutLinks = () => (
        <>
            <Link to="/login" className="nav-item" onClick={handleNavCollapse}>
                Log In
            </Link>
            <Link to="/signup" className="nav-item" onClick={handleNavCollapse}>
                Sign Up
            </Link>
        </>
    )

    const loggedInLinks = () => (
        <>
            <Link to="/profile" className="nav-item" onClick={handleNavCollapse}>
                Profile
            </Link>
            <Link to="/" className="nav-item" onClick={handleLogout}>
                Logout
            </Link>
        </>
    )

    return (
        <nav className="navbar">
            <Link to="/" className="navbar__logo">
                <img src={logo} alt="Index My Page" className="navbar__logo-img" />
            </Link>
            <div className="nav-menu">
                <button className="nav-toggle" onClick={handleNavCollapse}>
                    {isNavCollapsed ? '☰' : '✖'}
                </button>
                <div className={`${isNavCollapsed ? 'nav-links' : 'nav-links active'}`}>
                    <Link
                        to="/"
                        className="nav-item"
                        onClick={handleNavCollapse}
                    >
                        Home
                    </Link>
                    <Link to="/tools" className="nav-item" onClick={handleNavCollapse}>
                        Tools
                    </Link>
                    <Link
                        to="/pricing"
                        className="nav-item"
                        onClick={handleNavCollapse}
                    >
                        Pricing
                    </Link>
                    <Link
                        to="http://indexmypage.com/resources"
                        className="nav-item"
                        onClick={handleNavCollapse}
                    >
                        Resources
                    </Link>
                    {isLoggedIn ? loggedInLinks() : loggedOutLinks()}
                </div>
            </div>
        </nav>
    )
}

export default NavBar
