import React, { useState, useEffect } from 'react'
import { useAuth } from '../../AuthContext'
import axios from 'axios'
import './home.css'

const apiUrl = process.env.REACT_APP_API_URL

const Home = () => {
    const [error, setError] = useState(null)
    const { user, isLoggedIn } = useAuth()

    const startAuth = async () => {
        try {
            const authResponse = await axios.get(`${apiUrl}/start_auth`, {
                withCredentials: true
            })
            window.location.href = authResponse?.data?.url
        } catch (error) {
            console.error('Error starting authentication:', error)
        }
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search)
        const error = searchParams.get('error')
        if (error) {
            setError(error)
        }
    }, [])

    return (
        <div className="home">
            <h1 className="home-heading">
                Welcome{isLoggedIn && ` ${user.username}`}!
            </h1>
            {isLoggedIn
                ? (
                    <button
                        type="button"
                        className="authenticate-button"
                        onClick={startAuth}
                    >
                        Add Search Console Account
                    </button>
                )
                : (
                    <p>
                        <a href="/login">Log In</a> to get started.
                    </p>
                )}
            {error && <p className="error">{error}</p>}
        </div>
    )
}

export default Home
