import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import { AuthProvider } from './AuthContext'

import Home from './pages/Home'
import Login from './pages/Login'
import SignUp from './pages/SignUp'
import Tools from './pages/Tools'
import NavBar from './components/NavBar'
import Profile from './pages/Profile'
import Pricing from './pages/Pricing'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword'
import './App.css'

const App = () => {
    return (
        <div className="App">
            <Router>
                <AuthProvider>
                    <NavBar />
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route exact path="/login" element={<Login />} />
                        <Route exact path="/signup" element={<SignUp />} />
                        <Route exact path="/tools" element={<Tools />} />
                        <Route exact path="/profile" element={<Profile />} />
                        <Route exact path="/pricing" element={<Pricing />} />
                        <Route exact path="/forgot" element={<ForgotPassword />} />
                        <Route exact path="/reset" element={<ResetPassword />} />
                    </Routes>
                </AuthProvider>
            </Router>
        </div>
    )
}

export default App
