import React, { useState } from 'react'
import Axios from 'axios'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import '../../styles/Forms.css'

const apiUrl = process.env.REACT_APP_API_URL

const Login = () => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm()

    const navigate = useNavigate()
    const [error, setError] = useState(null)

    const onSubmit = async (data) => {
        try {
            await Axios.post(`${apiUrl}/login`, data, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            navigate('/')
        } catch (error) {
            console.error('Login failed:', error)
            setError(error.response?.data?.message || 'Error logging in')
        }
    }
    return (
        <div className="form-container">
            <h1>Login</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        id="email"
                        type="email"
                        {...register('email', { required: true })}
                        placeholder="Email"
                    />
                    {errors.email && <p>Email is required.</p>}
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input
                        id="password"
                        type="password"
                        {...register('password', { required: true })}
                        placeholder="Password"
                    />
                    {errors.password && <p>Password is required.</p>}
                </div>
                {error && <p className="form-error">{error}</p>}
                <p className="switch-form">
          Don't have an account?{' '}
                    <button
                        className="switch-form-button"
                        type="button"
                        onClick={() => {
                            navigate('/signup')
                        }}
                    >
            Sign Up
                    </button>
                </p>
                <p className="switch-form">
                    <button
                        className="switch-form-button"
                        type="button"
                        onClick={() => {
                            navigate('/forgot')
                        }}
                    >
            Forgot Password
                    </button>
                </p>
                <button className="form-submit-button" type="submit">
          Login
                </button>
            </form>
        </div>
    )
}

export default Login
