import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Axios from 'axios'
import '../../styles/Forms.css' // Ensure this is the same CSS file used for the Login component

const apiUrl = process.env.REACT_APP_API_URL

const SignUp = () => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm()
    const navigate = useNavigate()

    const [error, setError] = useState(null)

    const onSubmit = async (data) => {
        try {
            await Axios.post(`${apiUrl}/create_user`, data)
            navigate('/login')
            // Handle success (e.g., navigate to dashboard or show success message)
        } catch (error) {
            console.error('Failed to create user:', error)
            setError(error.response?.data?.message || 'Error creating user')
        }
    }

    return (
        <div className="form-container">
            <h1>Sign Up</h1>
            <p className="promo">Join now and get 3 free credits!</p>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                    <label htmlFor="username">Username:</label>
                    <input
                        id="username"
                        type="text"
                        {...register('username', { required: 'Username is required' })}
                        placeholder="Username"
                    />
                    {errors.username && <p>{errors.username.message}</p>}
                </div>

                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        id="email"
                        type="email"
                        {...register('email', {
                            required: 'Email is required',
                            pattern: {
                                value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                                message: 'Email is not valid'
                            }
                        })}
                        placeholder="Email"
                    />
                    {errors.email && <p>{errors.email.message}</p>}
                </div>

                <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input
                        id="password"
                        type="password"
                        {...register('password', {
                            required: 'Password is required',
                            minLength: {
                                value: 6,
                                message: 'Password must have at least 6 characters'
                            }
                        })}
                        placeholder="Password"
                    />
                    {errors.password && <p>{errors.password.message}</p>}
                </div>
                {error && <p className="form-error">{error}</p>}
                <p className="switch-form">
          Already have an account?{' '}
                    <button
                        className="switch-form-button"
                        type="button"
                        onClick={() => {
                            navigate('/login')
                        }}
                    >
            Login
                    </button>
                </p>
                <button className="form-submit-button" type="submit">
          Sign Up
                </button>
            </form>
        </div>
    )
}

export default SignUp
