import React, { useState } from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import '../../styles/Forms.css'

const apiUrl = process.env.REACT_APP_API_URL

const ResetPassword = () => {
    const [error, setError] = useState(null)
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm()
    const navigate = useNavigate()

    const onSubmit = async (data) => {
        const { password, confirmPassword } = data
        if (password && password !== confirmPassword) {
            setError('Passwords do not match')
            return
        }
        const token = new URLSearchParams(window.location.search).get('token')
        const payload = { password, token }
        try {
            await axios.post(`${apiUrl}/reset`, payload, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            navigate('/login')
        } catch (error) {
            setError(error.response?.data?.message || 'Error resetting password')
        }
    }

    return (
        <div className="form-container">
            <h1>Reset Password</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                    <label htmlFor="password">Password:</label>
                    <input
                        id="password"
                        type="password"
                        {...register('password', {
                            required: 'Password is required',
                            minLength: {
                                value: 6,
                                message: 'Password must have at least 6 characters'
                            }
                        })}
                        placeholder="Password"
                    />
                    {errors.password && <p>{errors.password.message}</p>}
                </div>
                <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password:</label>
                    <input
                        id="confirmPassword"
                        type="password"
                        {...register('confirmPassword', { required: 'Confirmation is reqiured' })}
                        placeholder="Confirm Password"
                    />
                    {errors.confirmPassword && <p>Confirmation is required.</p>}
                </div>
                {error && <p className="form-error">{error}</p>}
                <button className="form-submit-button" type="submit">
                    Reset Password
                </button>
            </form>
        </div>
    )
}

export default ResetPassword
