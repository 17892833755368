import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import './PublishUrlForm.css'
import { useAuth } from '../../AuthContext'
import Select from 'react-select'
import validURL from '../../utils/validURL'

const apiUrl = process.env.REACT_APP_API_URL

const PublishUrlForm = () => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting }
    } = useForm()

    const { setUser, accounts } = useAuth()

    const [response, setResponse] = useState('')
    const [failedUrls, setFailedUrls] = useState([])
    const [error, setError] = useState(null)
    const [removeUrls, setRemoveUrls] = useState(false)
    const [selectedAccount, setSelectedAccount] = useState(null)

    const accountOptions = accounts && accounts.map((account) => ({
        value: account.id,
        label: account.google_email
    }))

    const onSubmit = async (data) => {
        if (!selectedAccount) {
            setError('Please select an account')
            return
        }
        try {
            const urls = data.url.split(/\r?\n/).filter((url) => url)
            const payload = { url: urls, account_id: selectedAccount?.value }
            const method = removeUrls ? 'delete' : 'post'

            const response = await axios({
                method,
                url: `${apiUrl}/publish_url`,
                data: payload,
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            const { failed = [], user: newUser, message, error } = response.data

            setFailedUrls(failed)
            setUser(newUser)
            setResponse(message)
            setError(error || null)
        } catch (error) {
            handleErrorResponse(error)
        }
    }

    const startAuth = async () => {
        try {
            const authResponse = await axios.get(`${apiUrl}/start_auth`, {
                withCredentials: true
            })
            window.location.href = authResponse?.data?.url
        } catch (error) {
            setError(error.response?.data?.message || 'Error starting authentication')
        }
    }

    const handleErrorResponse = async (error) => {
        const { redirect } = error.response?.data
        if (error.response?.status === 401 && redirect === '/start_auth') {
            await startAuth()
        } else {
            setResponse(error.response?.data?.message || 'Failed to publish URL')
        }
    }

    return (
        <div>
            <div className="publish-form-container">
                <form onSubmit={handleSubmit(onSubmit)} className="publish-form">
                    <textarea
                        {...register('url', {
                            required: 'URL(s) are required',
                            validate: (input) => {
                                const urls = input.split(/\r?\n/).filter((url) => url)
                                return urls.every(validURL) || 'Please enter valid URL(s)'
                            }
                        })}
                        placeholder="https://example.com"
                        className={`publish-input ${errors.url ? 'input-error' : ''}`}
                    />
                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className="publish-button"
                    >
                        {isSubmitting
                            ? removeUrls
                                ? 'Removing...'
                                : 'Publishing...'
                            : removeUrls
                                ? 'Remove'
                                : 'Publish'}
                    </button>
                </form>
                <div className="toggle-container">
                    <label>
                        <input
                            type="checkbox"
                            checked={removeUrls}
                            onChange={(e) => setRemoveUrls(e.target.checked)}
                        />
                        <span> Remove URLs</span>
                    </label>
                </div>
                <Select
                    options={accountOptions}
                    onChange={(selected) => setSelectedAccount(selected)}
                    value={selectedAccount}
                    placeholder="Select account"
                    className='account-select'
                />
                <button className="authenticate-button"
                    onClick={startAuth}
                >
                    Add Account
                </button>
                {errors.url && <p className="error-message">{errors.url.message}</p>}
                {response && <div className="response-message">{response}</div>}
            </div>
            {error && <div className="error-message">{error}</div>}
            <div>
                {failedUrls.length > 0 && (
                    <div>
                        <h3>The following URL(s) failed:</h3>
                        <ul>
                            {failedUrls.map((url, index) => (
                                <li key={index}>{url}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    )
}

export default PublishUrlForm
