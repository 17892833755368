import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import './pricing2.css'

const apiUrl = process.env.REACT_APP_API_URL

const Pricing = () => {
    const [plans, setPlans] = useState([])
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    const greenCheckmark = (
        <svg className="checkmark" viewBox="0 0 24 24" stroke="currentColor">
            <path d="M5 13l4 4L19 7"></path>
        </svg>
    )

    const startCheckout = async (planId) => {
        try {
            const response = await axios.put(
                `${apiUrl}/sub`,
                { id: planId },
                { withCredentials: true }
            )
            if (response.data.checkout_url) {
                window.location.href = response.data.checkout_url
            } else {
                setError('Failed to initiate payment process')
            }
        } catch (error) {
            setError('Error initiating payment process')
        }
    }

    const fetchPlans = async () => {
        try {
            setLoading(true)
            const response = await axios.get(`${apiUrl}/products`, {
                withCredentials: true
            })
            const { data: { products } } = response
            setLoading(false)
            setPlans(products)
        } catch (error) {
            setLoading(false)
            setError(error?.response?.data?.message || 'Error fetching plans')
        }
    }

    useEffect(() => {
        fetchPlans()
    }, [])

    return (
        <section className="pricing-section">
            <div className="container">
                <div className="title-section">
                    <h2 className="section-title">Our Plans</h2>
                    <p className="section-subtitle">Designed for digital success</p>
                </div>
                {loading && <div className="loading-plans">Loading plans...</div>}
                <div className="plans-container">
                    {!loading && plans.length > 0 &&
                        plans.map((plan, index) => (
                            <div
                                key={index}
                                className={`plan ${plan?.trial_days ? 'free-trial' : ''}`}
                                onClick={() => startCheckout(plan.id)}
                            >
                                <h3 className="plan-title">{plan.name}</h3>
                                <div className="price-container">
                                    <span className="price">${plan.price}</span>
                                    <span className="price-period">/month</span>
                                </div>
                                <div className="plan-description">
                                    {plan.description
                                        .split('. ')
                                        .filter(Boolean)
                                        .map((part, i) => (
                                            <div key={i} className="description-item">
                                                <div className="description-checkmark">
                                                    {greenCheckmark}
                                                </div>
                                                <span className="description-text">{part}</span>
                                            </div>
                                        ))}
                                </div>
                                <Link
                                    to="#"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        startCheckout(plan.id)
                                    }}
                                    className="get-started-btn"
                                >
                                    Get started
                                </Link>
                            </div>
                        ))}
                </div>
                {error && <div className="error-message">{error}</div>}
            </div>
        </section>
    )
}

export default Pricing
