import React, { useState } from 'react'
import axios from 'axios'

import { useForm } from 'react-hook-form'

import '../../styles/Forms.css'

const apiUrl = process.env.REACT_APP_API_URL

const ForgotPassword = () => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm()

    const [message, setMessage] = useState('')
    const [success, setSuccess] = useState(false)

    const sendEmail = async (data) => {
        try {
            const response = await axios.post(`${apiUrl}/forgot`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            setMessage(response.data.message)
            setSuccess(true)
        } catch (error) {
            setMessage('Error sending email')
        }
    }

    return (
        <div className="form-container">
            <h1>Forgot Password</h1>
            <form onSubmit={handleSubmit(sendEmail)}>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        id="email"
                        type="email"
                        {...register('email', { required: 'Email is required' })}
                        placeholder="Email"
                    />
                    {errors.email && <p>Email is required.</p>}
                </div>
                {message && <p className="form-error">{message}</p>}
                {
                    success
                        ? (
                            <p className="form-success">Please check your email!</p>
                        )
                        : (
                            <button className="form-submit-button" type="submit">
                              Send Email
                            </button>
                        )
                }
            </form>
        </div>
    )
}

export default ForgotPassword
